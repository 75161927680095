<template>
  <div class="uav-device-item-wrap">
    <div class="uav-device-item">
      <img :src="url" alt="" fit="cover" width="120px" />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      url: 'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg',
    };
  },
};
</script>

<style lang="scss" scoped>
.uav-device-item-wrap {
  height: 120px;
  padding: 10px;
  background: #fff;
  display: flex;
  align-items: center;
}
</style>
