<template>
  <el-card class="page-container">
    <el-form :inline="true" slot="header" size="small">
      <el-form-item label="设备型号">
        <el-input v-model="uavMode"></el-input>
      </el-form-item>
      <el-form-item style="float: right">
        <el-button type="primary" @click="dialogVisible = true">新增</el-button>
      </el-form-item>
    </el-form>
    <el-table height="100" :data="uavModeList">
      <el-table-column label="序号" type="index" align="center" width="60px"></el-table-column>
      <el-table-column label="名称" align="center" prop="UAVName"></el-table-column>
      <el-table-column label="型号" align="center" prop="UAVModel"></el-table-column>
      <el-table-column label="品牌" align="center" prop="UAVModel"></el-table-column>
      <el-table-column label="起飞重量（克）" align="center" prop="maxFlightWeight"></el-table-column>
      <el-table-column label="对角线轴距(mm)" align="center" prop="wheelbase"></el-table-column>
      <el-table-column label="垂直速度(m/s)" align="center" prop="maxRiseSpeed">
        <template slot-scope="{ row }">
          <span><i class="el-icon-top"></i> {{ row.maxRiseSpeed }}</span>
          <span><i class="el-icon-bottom"></i>{{ row.maxDropSpeed }}</span>
        </template>
      </el-table-column>
      <el-table-column label="水平速度(m/s)" align="center" prop="maxFlightSpeed"></el-table-column>
      <el-table-column label="起飞海拔高度(m)" align="center" prop="maxDeparturesAltitude"></el-table-column>
      <el-table-column label="飞行时间(分钟)" align="center" prop="maxFlightTime"></el-table-column>
      <el-table-column label="续航里程(km)" align="center" prop="maxFlightMileage"></el-table-column>
      <el-table-column label="抗风速度(m/s)" align="center" prop="maxGaleSpeed"></el-table-column>
      <el-table-column label="抗风等级" align="center" prop="maxGaleLevel"></el-table-column>
      <el-table-column label="可倾斜角度(度)" align="center" prop="maxSlope"></el-table-column>
      <el-table-column label="旋转角速度(度/秒)" align="center" prop="maxSpinningSpeed"></el-table-column>
      <el-table-column label="卫星定位模块" align="center" prop="satelliteModule"></el-table-column>
      <el-table-column label="发射功率" align="center" prop="transmitPower"></el-table-column>
      <el-table-column label="天线数量" align="center" prop="antennaCount"></el-table-column>
      <el-table-column label="悬停精度(m)" align="center" prop="hoverAccuracy"></el-table-column>
      <el-table-column label="储存信息" align="center" prop="deviceMemory"></el-table-column>
      <el-table-column label="工作环境温度" align="center" prop="maxCardMemory"></el-table-column>
      <el-table-column label="三维尺寸" align="center" prop="maxCardMemory"></el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="{ row }">
          <el-button type="text">编辑</el-button>
          <el-button type="text">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <PaginationBar @change:pageNumber="getPageNumber" />
    <el-dialog :visible.sync="dialogVisible" title="新建无人机设备" :close-on-click-modal="false">
      <el-form label-position="left" size="mini">
        <el-row :gutter="20">
          <h2 class="message-title"><i class="el-icon-caret-right"></i> 基本信息</h2>
          <el-col :span="8">
            <el-form-item label="名称">
              <el-input v-model="uavDeviceInfo.UAVName"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="型号">
              <el-input v-model="uavDeviceInfo.UAVModel"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="品牌">
              <el-input v-model="uavDeviceInfo.UAVBrand"></el-input>
            </el-form-item>
          </el-col>
          <h2 class="message-title"><i class="el-icon-caret-right"></i> 无人机参数</h2>
          <el-col :span="8">
            <el-form-item label="无人机最大起飞重量（克）">
              <el-input v-model="uavDeviceInfo.maxFlightWeight"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="无人机对角线轴距(mm)">
              <el-input v-model="uavDeviceInfo.wheelbase"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="最大上升速度(m/s)">
              <el-input v-model="uavDeviceInfo.maxRiseSpeed"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="最大下降速度(m/s)">
              <el-input v-model="uavDeviceInfo.maxDropSpeed"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="卫星定位模块">
              <el-input v-model="uavDeviceInfo.satelliteModule"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="发射功率">
              <el-input v-model="uavDeviceInfo.transmitPower"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="8">
            <el-form-item label="天线数量">
              <el-input v-model="uavDeviceInfo.antennaCount"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="悬停精度(m)">
              <el-input v-model="uavDeviceInfo.hoverAccuracy"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="机载内存(GB)">
              <el-input v-model="uavDeviceInfo.deviceMemory"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="8">
            <el-form-item label="支持存储卡类型">
              <el-input v-model="uavDeviceInfo.SDCardType"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="最低工作环境温度(摄氏度)">
              <el-input v-model="uavDeviceInfo.workMinCelsius"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="最高工作环境温度(摄氏度)">
              <el-input v-model="uavDeviceInfo.workMaxCelsius"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="8">
            <el-form-item label="三维尺寸(mm) 长">
              <el-input v-model="uavDeviceInfo.UAVSizeLength"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="三维尺寸(mm) 宽">
              <el-input v-model="uavDeviceInfo.UAVSizeWidth"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="三维尺寸(mm) 高">
              <el-input v-model="uavDeviceInfo.UAVSizeHeight"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item>
          <el-button type="primary" size="mini" @click="dialogVisible = true">确认</el-button>
          <el-button size="mini" @click="dialogVisible = false">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </el-card>
</template>

<script>
import List from './components/List.vue';
import PaginationBar from '@/components/Pagination';
import { getDevice_SUAV_List, insertDevice_SUAV_Info } from '@/api/deviceStore.js';
import { source } from './dataSource.js';
console.log(source);
export default {
  components: {
    List,
    PaginationBar,
  },
  data() {
    return {
      dialogVisible: false,
      uavModeList: source.uav,
      uavDeviceInfo: {
        UAVName: '',
        UAVModel: '',
        UAVBrand: '',
        maxFlightWeight: '',
        wheelbase: '',
        maxRiseSpeed: '',
        maxDropSpeed: '',
        satelliteModule: '',
        transmitPower: '',
        antennaCount: '',
        hoverAccuracy: '',
        deviceMemory: '',
        SDCardType: '',
        workMinCelsius: '',
        workMaxCelsius: '',
        UAVSizeLength: '',
        UAVSizeWidth: '',
        UAVSizeHeight: '',
      },
      uavMode: '',
      tableConfig: {
        page: 1,
        size: 20,
      },
    };
  },
  created() {
    this.getDevice_SUAV_List(this.tableConfig);
  },
  methods: {
    getPageNumber(page) {
      console.log(page);
    },

    getDevice_SUAV_List({ page = 1, size = 20 }) {
      getDevice_SUAV_List({ page, size });
    },

    //新增无人机信息
    insertDevice_SUAV_Info() {
      insertDevice_SUAV_Info(this.uavDeviceInfo).then((response) => {
        console.log(response);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../main-layout.scss';
</style>

<style lang="scss"></style>
