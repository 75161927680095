export const source ={
  uav:[
    {
      UAVName: '经纬 M300 RTK',
      UAVModel: 'M300 RTK',
      UAVBrand: 'DJI',
      maxFlightWeight: '12',
      wheelbase: '312',
      maxRiseSpeed: '5',
      maxDropSpeed: '5',
      satelliteModule: 'eqw',
      transmitPower: '3000',
      antennaCount: '12',
      hoverAccuracy: 'fdsa',
      deviceMemory: 'fads',
      SDCardType: 'fdas',
      workMinCelsius: 'fdas',
      workMaxCelsius: 'ffads',
      UAVSizeLength: 'fdas',
      UAVSizeWidth: 'fad',
      UAVSizeHeight: 'fads',
    },
    {
      UAVName: '经纬 M300 RTK',
      UAVModel: 'M300 RTK',
      UAVBrand: 'DJI',
      maxFlightWeight: '12',
      wheelbase: '312',
      maxRiseSpeed: '5',
      maxDropSpeed: '5',
      satelliteModule: 'eqw',
      transmitPower: '3000',
      antennaCount: '12',
      hoverAccuracy: 'fdsa',
      deviceMemory: 'fads',
      SDCardType: 'fdas',
      workMinCelsius: 'fdas',
      workMaxCelsius: 'ffads',
      UAVSizeLength: 'fdas',
      UAVSizeWidth: 'fad',
      UAVSizeHeight: 'fads',
    },
    {
      UAVName: '经纬 M300 RTK',
      UAVModel: 'M300 RTK',
      UAVBrand: 'DJI',
      maxFlightWeight: '12',
      wheelbase: '312',
      maxRiseSpeed: '5',
      maxDropSpeed: '5',
      satelliteModule: 'eqw',
      transmitPower: '3000',
      antennaCount: '12',
      hoverAccuracy: 'fdsa',
      deviceMemory: 'fads',
      SDCardType: 'fdas',
      workMinCelsius: 'fdas',
      workMaxCelsius: 'ffads',
      UAVSizeLength: 'fdas',
      UAVSizeWidth: 'fad',
      UAVSizeHeight: 'fads',
    },
    {
      UAVName: '经纬 M300 RTK',
      UAVModel: 'M300 RTK',
      UAVBrand: 'DJI',
      maxFlightWeight: '12',
      wheelbase: '312',
      maxRiseSpeed: '5',
      maxDropSpeed: '5',
      satelliteModule: 'eqw',
      transmitPower: '3000',
      antennaCount: '12',
      hoverAccuracy: 'fdsa',
      deviceMemory: 'fads',
      SDCardType: 'fdas',
      workMinCelsius: 'fdas',
      workMaxCelsius: 'ffads',
      UAVSizeLength: 'fdas',
      UAVSizeWidth: 'fad',
      UAVSizeHeight: 'fads',
    },
    {
      UAVName: '经纬 M300 RTK',
      UAVModel: 'M300 RTK',
      UAVBrand: 'DJI',
      maxFlightWeight: '12',
      wheelbase: '312',
      maxRiseSpeed: '5',
      maxDropSpeed: '5',
      satelliteModule: 'eqw',
      transmitPower: '3000',
      antennaCount: '12',
      hoverAccuracy: 'fdsa',
      deviceMemory: 'fads',
      SDCardType: 'fdas',
      workMinCelsius: 'fdas',
      workMaxCelsius: 'ffads',
      UAVSizeLength: 'fdas',
      UAVSizeWidth: 'fad',
      UAVSizeHeight: 'fads',
    },
    {
      UAVName: '经纬 M300 RTK',
      UAVModel: 'M300 RTK',
      UAVBrand: 'DJI',
      maxFlightWeight: '12',
      wheelbase: '312',
      maxRiseSpeed: '5',
      maxDropSpeed: '5',
      satelliteModule: 'eqw',
      transmitPower: '3000',
      antennaCount: '12',
      hoverAccuracy: 'fdsa',
      deviceMemory: 'fads',
      SDCardType: 'fdas',
      workMinCelsius: 'fdas',
      workMaxCelsius: 'ffads',
      UAVSizeLength: 'fdas',
      UAVSizeWidth: 'fad',
      UAVSizeHeight: 'fads',
    },
    {
      UAVName: '经纬 M300 RTK',
      UAVModel: 'M300 RTK',
      UAVBrand: 'DJI',
      maxFlightWeight: '12',
      wheelbase: '312',
      maxRiseSpeed: '5',
      maxDropSpeed: '5',
      satelliteModule: 'eqw',
      transmitPower: '3000',
      antennaCount: '12',
      hoverAccuracy: 'fdsa',
      deviceMemory: 'fads',
      SDCardType: 'fdas',
      workMinCelsius: 'fdas',
      workMaxCelsius: 'ffads',
      UAVSizeLength: 'fdas',
      UAVSizeWidth: 'fad',
      UAVSizeHeight: 'fads',
    },
    {
      UAVName: '经纬 M300 RTK',
      UAVModel: 'M300 RTK',
      UAVBrand: 'DJI',
      maxFlightWeight: '12',
      wheelbase: '312',
      maxRiseSpeed: '5',
      maxDropSpeed: '5',
      satelliteModule: 'eqw',
      transmitPower: '3000',
      antennaCount: '12',
      hoverAccuracy: 'fdsa',
      deviceMemory: 'fads',
      SDCardType: 'fdas',
      workMinCelsius: 'fdas',
      workMaxCelsius: 'ffads',
      UAVSizeLength: 'fdas',
      UAVSizeWidth: 'fad',
      UAVSizeHeight: 'fads',
    },
    {
      UAVName: '经纬 M300 RTK',
      UAVModel: 'M300 RTK',
      UAVBrand: 'DJI',
      maxFlightWeight: '12',
      wheelbase: '312',
      maxRiseSpeed: '5',
      maxDropSpeed: '5',
      satelliteModule: 'eqw',
      transmitPower: '3000',
      antennaCount: '12',
      hoverAccuracy: 'fdsa',
      deviceMemory: 'fads',
      SDCardType: 'fdas',
      workMinCelsius: 'fdas',
      workMaxCelsius: 'ffads',
      UAVSizeLength: 'fdas',
      UAVSizeWidth: 'fad',
      UAVSizeHeight: 'fads',
    },
    {
      UAVName: '经纬 M300 RTK',
      UAVModel: 'M300 RTK',
      UAVBrand: 'DJI',
      maxFlightWeight: '12',
      wheelbase: '312',
      maxRiseSpeed: '5',
      maxDropSpeed: '5',
      satelliteModule: 'eqw',
      transmitPower: '3000',
      antennaCount: '12',
      hoverAccuracy: 'fdsa',
      deviceMemory: 'fads',
      SDCardType: 'fdas',
      workMinCelsius: 'fdas',
      workMaxCelsius: 'ffads',
      UAVSizeLength: 'fdas',
      UAVSizeWidth: 'fad',
      UAVSizeHeight: 'fads',
    },
    {
      UAVName: '经纬 M300 RTK',
      UAVModel: 'M300 RTK',
      UAVBrand: 'DJI',
      maxFlightWeight: '12',
      wheelbase: '312',
      maxRiseSpeed: '5',
      maxDropSpeed: '5',
      satelliteModule: 'eqw',
      transmitPower: '3000',
      antennaCount: '12',
      hoverAccuracy: 'fdsa',
      deviceMemory: 'fads',
      SDCardType: 'fdas',
      workMinCelsius: 'fdas',
      workMaxCelsius: 'ffads',
      UAVSizeLength: 'fdas',
      UAVSizeWidth: 'fad',
      UAVSizeHeight: 'fads',
    },
    {
      UAVName: '经纬 M300 RTK',
      UAVModel: 'M300 RTK',
      UAVBrand: 'DJI',
      maxFlightWeight: '12',
      wheelbase: '312',
      maxRiseSpeed: '5',
      maxDropSpeed: '5',
      satelliteModule: 'eqw',
      transmitPower: '3000',
      antennaCount: '12',
      hoverAccuracy: 'fdsa',
      deviceMemory: 'fads',
      SDCardType: 'fdas',
      workMinCelsius: 'fdas',
      workMaxCelsius: 'ffads',
      UAVSizeLength: 'fdas',
      UAVSizeWidth: 'fad',
      UAVSizeHeight: 'fads',
    },
    {
      UAVName: '经纬 M300 RTK',
      UAVModel: 'M300 RTK',
      UAVBrand: 'DJI',
      maxFlightWeight: '12',
      wheelbase: '312',
      maxRiseSpeed: '5',
      maxDropSpeed: '5',
      satelliteModule: 'eqw',
      transmitPower: '3000',
      antennaCount: '12',
      hoverAccuracy: 'fdsa',
      deviceMemory: 'fads',
      SDCardType: 'fdas',
      workMinCelsius: 'fdas',
      workMaxCelsius: 'ffads',
      UAVSizeLength: 'fdas',
      UAVSizeWidth: 'fad',
      UAVSizeHeight: 'fads',
    },
    {
      UAVName: '经纬 M300 RTK',
      UAVModel: 'M300 RTK',
      UAVBrand: 'DJI',
      maxFlightWeight: '12',
      wheelbase: '312',
      maxRiseSpeed: '5',
      maxDropSpeed: '5',
      satelliteModule: 'eqw',
      transmitPower: '3000',
      antennaCount: '12',
      hoverAccuracy: 'fdsa',
      deviceMemory: 'fads',
      SDCardType: 'fdas',
      workMinCelsius: 'fdas',
      workMaxCelsius: 'ffads',
      UAVSizeLength: 'fdas',
      UAVSizeWidth: 'fad',
      UAVSizeHeight: 'fads',
    },
    {
      UAVName: '经纬 M300 RTK',
      UAVModel: 'M300 RTK',
      UAVBrand: 'DJI',
      maxFlightWeight: '12',
      wheelbase: '312',
      maxRiseSpeed: '5',
      maxDropSpeed: '5',
      satelliteModule: 'eqw',
      transmitPower: '3000',
      antennaCount: '12',
      hoverAccuracy: 'fdsa',
      deviceMemory: 'fads',
      SDCardType: 'fdas',
      workMinCelsius: 'fdas',
      workMaxCelsius: 'ffads',
      UAVSizeLength: 'fdas',
      UAVSizeWidth: 'fad',
      UAVSizeHeight: 'fads',
    },
    {
      UAVName: '经纬 M300 RTK',
      UAVModel: 'M300 RTK',
      UAVBrand: 'DJI',
      maxFlightWeight: '12',
      wheelbase: '312',
      maxRiseSpeed: '5',
      maxDropSpeed: '5',
      satelliteModule: 'eqw',
      transmitPower: '3000',
      antennaCount: '12',
      hoverAccuracy: 'fdsa',
      deviceMemory: 'fads',
      SDCardType: 'fdas',
      workMinCelsius: 'fdas',
      workMaxCelsius: 'ffads',
      UAVSizeLength: 'fdas',
      UAVSizeWidth: 'fad',
      UAVSizeHeight: 'fads',
    },
    {
      UAVName: '经纬 M300 RTK',
      UAVModel: 'M300 RTK',
      UAVBrand: 'DJI',
      maxFlightWeight: '12',
      wheelbase: '312',
      maxRiseSpeed: '5',
      maxDropSpeed: '5',
      satelliteModule: 'eqw',
      transmitPower: '3000',
      antennaCount: '12',
      hoverAccuracy: 'fdsa',
      deviceMemory: 'fads',
      SDCardType: 'fdas',
      workMinCelsius: 'fdas',
      workMaxCelsius: 'ffads',
      UAVSizeLength: 'fdas',
      UAVSizeWidth: 'fad',
      UAVSizeHeight: 'fads',
    },
    {
      UAVName: '经纬 M300 RTK',
      UAVModel: 'M300 RTK',
      UAVBrand: 'DJI',
      maxFlightWeight: '12',
      wheelbase: '312',
      maxRiseSpeed: '5',
      maxDropSpeed: '5',
      satelliteModule: 'eqw',
      transmitPower: '3000',
      antennaCount: '12',
      hoverAccuracy: 'fdsa',
      deviceMemory: 'fads',
      SDCardType: 'fdas',
      workMinCelsius: 'fdas',
      workMaxCelsius: 'ffads',
      UAVSizeLength: 'fdas',
      UAVSizeWidth: 'fad',
      UAVSizeHeight: 'fads',
    },
    {
      UAVName: '经纬 M300 RTK',
      UAVModel: 'M300 RTK',
      UAVBrand: 'DJI',
      maxFlightWeight: '12',
      wheelbase: '312',
      maxRiseSpeed: '5',
      maxDropSpeed: '5',
      satelliteModule: 'eqw',
      transmitPower: '3000',
      antennaCount: '12',
      hoverAccuracy: 'fdsa',
      deviceMemory: 'fads',
      SDCardType: 'fdas',
      workMinCelsius: 'fdas',
      workMaxCelsius: 'ffads',
      UAVSizeLength: 'fdas',
      UAVSizeWidth: 'fad',
      UAVSizeHeight: 'fads',
    },
    {
      UAVName: '经纬 M300 RTK',
      UAVModel: 'M300 RTK',
      UAVBrand: 'DJI',
      maxFlightWeight: '12',
      wheelbase: '312',
      maxRiseSpeed: '5',
      maxDropSpeed: '5',
      satelliteModule: 'eqw',
      transmitPower: '3000',
      antennaCount: '12',
      hoverAccuracy: 'fdsa',
      deviceMemory: 'fads',
      SDCardType: 'fdas',
      workMinCelsius: 'fdas',
      workMaxCelsius: 'ffads',
      UAVSizeLength: 'fdas',
      UAVSizeWidth: 'fad',
      UAVSizeHeight: 'fads',
    },
    {
      UAVName: '经纬 M300 RTK',
      UAVModel: 'M300 RTK',
      UAVBrand: 'DJI',
      maxFlightWeight: '12',
      wheelbase: '312',
      maxRiseSpeed: '5',
      maxDropSpeed: '5',
      satelliteModule: 'eqw',
      transmitPower: '3000',
      antennaCount: '12',
      hoverAccuracy: 'fdsa',
      deviceMemory: 'fads',
      SDCardType: 'fdas',
      workMinCelsius: 'fdas',
      workMaxCelsius: 'ffads',
      UAVSizeLength: 'fdas',
      UAVSizeWidth: 'fad',
      UAVSizeHeight: 'fads',
    },
    {
      UAVName: '经纬 M300 RTK',
      UAVModel: 'M300 RTK',
      UAVBrand: 'DJI',
      maxFlightWeight: '12',
      wheelbase: '312',
      maxRiseSpeed: '5',
      maxDropSpeed: '5',
      satelliteModule: 'eqw',
      transmitPower: '3000',
      antennaCount: '12',
      hoverAccuracy: 'fdsa',
      deviceMemory: 'fads',
      SDCardType: 'fdas',
      workMinCelsius: 'fdas',
      workMaxCelsius: 'ffads',
      UAVSizeLength: 'fdas',
      UAVSizeWidth: 'fad',
      UAVSizeHeight: 'fads',
    },
    {
      UAVName: '经纬 M300 RTK',
      UAVModel: 'M300 RTK',
      UAVBrand: 'DJI',
      maxFlightWeight: '12',
      wheelbase: '312',
      maxRiseSpeed: '5',
      maxDropSpeed: '5',
      satelliteModule: 'eqw',
      transmitPower: '3000',
      antennaCount: '12',
      hoverAccuracy: 'fdsa',
      deviceMemory: 'fads',
      SDCardType: 'fdas',
      workMinCelsius: 'fdas',
      workMaxCelsius: 'ffads',
      UAVSizeLength: 'fdas',
      UAVSizeWidth: 'fad',
      UAVSizeHeight: 'fads',
    },
    {
      UAVName: '经纬 M300 RTK',
      UAVModel: 'M300 RTK',
      UAVBrand: 'DJI',
      maxFlightWeight: '12',
      wheelbase: '312',
      maxRiseSpeed: '5',
      maxDropSpeed: '5',
      satelliteModule: 'eqw',
      transmitPower: '3000',
      antennaCount: '12',
      hoverAccuracy: 'fdsa',
      deviceMemory: 'fads',
      SDCardType: 'fdas',
      workMinCelsius: 'fdas',
      workMaxCelsius: 'ffads',
      UAVSizeLength: 'fdas',
      UAVSizeWidth: 'fad',
      UAVSizeHeight: 'fads',
    },
    {
      UAVName: '经纬 M300 RTK',
      UAVModel: 'M300 RTK',
      UAVBrand: 'DJI',
      maxFlightWeight: '12',
      wheelbase: '312',
      maxRiseSpeed: '5',
      maxDropSpeed: '5',
      satelliteModule: 'eqw',
      transmitPower: '3000',
      antennaCount: '12',
      hoverAccuracy: 'fdsa',
      deviceMemory: 'fads',
      SDCardType: 'fdas',
      workMinCelsius: 'fdas',
      workMaxCelsius: 'ffads',
      UAVSizeLength: 'fdas',
      UAVSizeWidth: 'fad',
      UAVSizeHeight: 'fads',
    },
    {
      UAVName: '经纬 M300 RTK',
      UAVModel: 'M300 RTK',
      UAVBrand: 'DJI',
      maxFlightWeight: '12',
      wheelbase: '312',
      maxRiseSpeed: '5',
      maxDropSpeed: '5',
      satelliteModule: 'eqw',
      transmitPower: '3000',
      antennaCount: '12',
      hoverAccuracy: 'fdsa',
      deviceMemory: 'fads',
      SDCardType: 'fdas',
      workMinCelsius: 'fdas',
      workMaxCelsius: 'ffads',
      UAVSizeLength: 'fdas',
      UAVSizeWidth: 'fad',
      UAVSizeHeight: 'fads',
    },
    {
      UAVName: '经纬 M300 RTK',
      UAVModel: 'M300 RTK',
      UAVBrand: 'DJI',
      maxFlightWeight: '12',
      wheelbase: '312',
      maxRiseSpeed: '5',
      maxDropSpeed: '5',
      satelliteModule: 'eqw',
      transmitPower: '3000',
      antennaCount: '12',
      hoverAccuracy: 'fdsa',
      deviceMemory: 'fads',
      SDCardType: 'fdas',
      workMinCelsius: 'fdas',
      workMaxCelsius: 'ffads',
      UAVSizeLength: 'fdas',
      UAVSizeWidth: 'fad',
      UAVSizeHeight: 'fads',
    },
    {
      UAVName: '经纬 M300 RTK',
      UAVModel: 'M300 RTK',
      UAVBrand: 'DJI',
      maxFlightWeight: '12',
      wheelbase: '312',
      maxRiseSpeed: '5',
      maxDropSpeed: '5',
      satelliteModule: 'eqw',
      transmitPower: '3000',
      antennaCount: '12',
      hoverAccuracy: 'fdsa',
      deviceMemory: 'fads',
      SDCardType: 'fdas',
      workMinCelsius: 'fdas',
      workMaxCelsius: 'ffads',
      UAVSizeLength: 'fdas',
      UAVSizeWidth: 'fad',
      UAVSizeHeight: 'fads',
    },
    {
      UAVName: '经纬 M300 RTK',
      UAVModel: 'M300 RTK',
      UAVBrand: 'DJI',
      maxFlightWeight: '12',
      wheelbase: '312',
      maxRiseSpeed: '5',
      maxDropSpeed: '5',
      satelliteModule: 'eqw',
      transmitPower: '3000',
      antennaCount: '12',
      hoverAccuracy: 'fdsa',
      deviceMemory: 'fads',
      SDCardType: 'fdas',
      workMinCelsius: 'fdas',
      workMaxCelsius: 'ffads',
      UAVSizeLength: 'fdas',
      UAVSizeWidth: 'fad',
      UAVSizeHeight: 'fads',
    },
    {
      UAVName: '经纬 M300 RTK',
      UAVModel: 'M300 RTK',
      UAVBrand: 'DJI',
      maxFlightWeight: '12',
      wheelbase: '312',
      maxRiseSpeed: '5',
      maxDropSpeed: '5',
      satelliteModule: 'eqw',
      transmitPower: '3000',
      antennaCount: '12',
      hoverAccuracy: 'fdsa',
      deviceMemory: 'fads',
      SDCardType: 'fdas',
      workMinCelsius: 'fdas',
      workMaxCelsius: 'ffads',
      UAVSizeLength: 'fdas',
      UAVSizeWidth: 'fad',
      UAVSizeHeight: 'fads',
    },
    {
      UAVName: '经纬 M300 RTK',
      UAVModel: 'M300 RTK',
      UAVBrand: 'DJI',
      maxFlightWeight: '12',
      wheelbase: '312',
      maxRiseSpeed: '5',
      maxDropSpeed: '5',
      satelliteModule: 'eqw',
      transmitPower: '3000',
      antennaCount: '12',
      hoverAccuracy: 'fdsa',
      deviceMemory: 'fads',
      SDCardType: 'fdas',
      workMinCelsius: 'fdas',
      workMaxCelsius: 'ffads',
      UAVSizeLength: 'fdas',
      UAVSizeWidth: 'fad',
      UAVSizeHeight: 'fads',
    },
    {
      UAVName: '经纬 M300 RTK',
      UAVModel: 'M300 RTK',
      UAVBrand: 'DJI',
      maxFlightWeight: '12',
      wheelbase: '312',
      maxRiseSpeed: '5',
      maxDropSpeed: '5',
      satelliteModule: 'eqw',
      transmitPower: '3000',
      antennaCount: '12',
      hoverAccuracy: 'fdsa',
      deviceMemory: 'fads',
      SDCardType: 'fdas',
      workMinCelsius: 'fdas',
      workMaxCelsius: 'ffads',
      UAVSizeLength: 'fdas',
      UAVSizeWidth: 'fad',
      UAVSizeHeight: 'fads',
    },
  ],
  hive:[
    {

    }
  ]
}