import request from '@/utils/request';
import { base_url } from '@/utils/BASE_URL';

const server = base_url.server;

//获取无人机设备列表
export function getDevice_SUAV_List({page = 1,size = 20,name = '',mode = '',brand = ''}){
  return request({
    url:`${server}/${base_url.core}/device_manage/drones?page=${page}&pageSize=${size}&UAVName=${name}&UAVMode=${mode}&UAVBrand=${brand}`,
    method:'get'
  })
}

//新增无人机设备信息
export function insertDevice_SUAV_Info(params){
  return request({
    url:`${server}/${base_url.core}/device_manage/drones`,
    method:'post',
    data:params
  })
}