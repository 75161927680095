<template>
  <div class="uav-device-list-wrap">
    <Item />
  </div>
</template>

<script>
import Item from './Item.vue';
export default {
  components: {
    Item,
  },
};
</script>

<style lang="scss" scoped>
.uav-device-list-wrap {
  padding: 10px;
}
</style>
